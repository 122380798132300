export class Tab {
    constructor() {
        this.tabTriggers = document.querySelectorAll('.js-movie_change');
        this.activeTab = {
            id: null,
        };
        this.registerEvent();
    }

    registerEvent() {
        this.activeTab.id = this.tabTriggers[0].dataset.tab;
        this.tabTriggers.forEach((el) => {
            el.addEventListener('click', this.changeActive.bind(this));
        });
    }

    changeActive(e) {
        const oldTrigger = document.querySelector(`[data-tab="${this.activeTab.id}"]`);
        const oldContainer = document.querySelector(`[data-tab-container="${this.activeTab.id}"]`);
        oldTrigger.classList.remove('-active');
        oldContainer.classList.remove('-active');

        const currentId = e.currentTarget.dataset.tab;
        const newContainer = document.querySelector(`[data-tab-container="${currentId}"]`);
        e.currentTarget.classList.add('-active');
        newContainer.classList.add('-active');
        this.activeTab.id = currentId;
    }
}
