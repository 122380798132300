export default class Twitter {
    constructor() {
        this.init();
    }

    init() {
        //this.onScroll();
    }

    onScroll() {
        const target = document.querySelector(".twitter");
        let loaded = false;

        const option = {
            root: null,
            rootMargin: "0px",
            threshold: [0],
        };
        // 交差した際の処理を記載
        const callback = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !loaded) {
                    this.setTwitterJS();
                    loaded = true;
                } else {
                }
            });
        };
        const observer = new IntersectionObserver(callback, option);
        observer.observe(target); // 監視を開始
    }

    setTwitterJS() {
        const head = document.getElementsByTagName("head")[0];
        const script = document.createElement("script");
        script.src = "https://platform.twitter.com/widgets.js";
        head.appendChild(script);
    }
}
