export default class KV {
    constructor() {
        this.currentKV = 1;
        this.timer = null;
        this.init();
    }

    init() {
        this.switchVisual();
    }

    switchVisual() {
        const mv = document.querySelector(".js-mainvisual");
        const btn = document.querySelector(".js-visualswitch");

        //初期設定
        mv.setAttribute("data-activekv", String(this.currentKV));

        btn.addEventListener("click", () => {
            clearTimeout(this.timer);
            mv.setAttribute("data-mvchange_transition", "0");

            if (this.currentKV === 1) {
                this.currentKV = 2;
            } else {
                this.currentKV = 1;
            }

            mv.setAttribute("data-activekv", String(this.currentKV));

            setTimeout(() => {
                mv.setAttribute("data-mvchange_transition", "1");
            }, 10);

            timer = setTimeout(() => {
                mv.setAttribute("data-mvchange_transition", "0");
            }, 1500);
        });
    }
}
