import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

export default class ScrollFunc {
    constructor() {
        this.init();
    }

    init() {
        this.onScroll();
        this.swicthAside();
    }

    onScroll() {
        const el = document.querySelector(".news__ttl");
        const scroller = document.querySelector(".mainvisual__scroller");

        ScrollTrigger.create({
            trigger: el,
            start: "top bottom",
            onEnter: () => {
                scroller.classList.add("-hide");
            },
            onLeaveBack: () => {
                scroller.classList.remove("-hide");
            },
        });
    }

    swicthAside() {
        const target = document.querySelector(".l-footer");
        const aside = document.querySelector(".aside__pvbnr");

        const option = {
            root: null,
            rootMargin: "0px",
            threshold: [0],
        };

        const callback = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    aside.classList.add("-hide");
                } else {
                    aside.classList.remove("-hide");
                }
            });
        };
        const observer = new IntersectionObserver(callback, option);
        observer.observe(target);
    }
}
