export default class Intro {
    constructor() {
        this.init();
    }

    init() {
        this.setRandomXY();

        setTimeout(() => {
            document.body.classList.add("-ready");
            this.introRandomTxt();
        }, 1000);

        setTimeout(() => {
            document.body.classList.add("-readyend");
        }, 6000);
    }

    setRandomXY() {
        const logo = document.querySelector(".loader__logo");
        const height = logo.offsetHeight;
        const width = logo.offsetWidth;
        let x = window.innerWidth * Math.random();
        let y = window.innerHeight * Math.random();

        if (y + height > window.innerHeight) {
            y -= height;
        }

        if (x + width > window.innerWidth) {
            x -= width;
        }

        logo.style.left = `${x}px`;
        logo.style.top = `${y}px`;
    }

    introRandomTxt() {
        const alphabet = [";", "-", ";", ";", "^", "#", "!", "&", "*", "+", ">", ">", "?", "!", "=", "0", "1", "2", "3", "o", "u", "v", "w", "z"];
        const body = document.querySelector(".js-transformtxt");
        let counter = 0;

        const change = () => {
            const rand = Math.floor(Math.random() * 25);
            body.textContent = alphabet[rand];
            counter++;
            body.classList.add("-k");

            if (counter < 40) {
                setTimeout(change, 30);
            } else {
                body.textContent = "k";
            }
        };

        setTimeout(change, 1500);
    }
}
