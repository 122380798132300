import Intro from "./classes/intro.es6";
import KV from "./classes/kv.es6";
import Twitter from "./classes/twitter.es6";
import ScrollFunc from "./classes/scrollfunc.es6";

export default class Top {
    constructor() {
        this.intro = new Intro();
        this.kv = new KV();
        this.twitter = new Twitter();
        this.scrollFunc = new ScrollFunc();
    }
}
